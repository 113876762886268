import React from 'react'
import OrangeLogo from "../../../assets/Image/OrangeLogo.svg"
import { MailSearch, MapPin, Phone } from 'lucide-react'
const LandingFooter = () => {
    return (
        <div className='w-full py-14 px-4'>
            <div className='w-full flex justify-around items-center'>
                <div className='w-full'>
                    <div className='w-full '><img src={OrangeLogo} alt='orangeLogo' className='w-10 h-10'/></div>
                    <div className='w-1/2 text-black font-bold break-words'>Orange Current Technologies Pvt. Ltd</div>
                    <div className='w-1/2 text-black font-medium break-words text-xs'>
                        Orange Current Technologies
                        develops industry-specific blockchain solutions for enterprises.
                    </div>
                </div>
                <div className='w-full space-y-2'>
                    <div className='w-1/2 text-black font-bold break-words'>Featured Links</div>
                    <div className='w-1/2 text-black font-medium break-words text-xs'>Renewable Energy
                        Net Zero Carbon
                        Blockchain Energy
                        Paperless Office
                        Peer to Peer</div>
                </div>

                <div className='w-full space-y-2'>
                    <div className='w-full text-center ml-6 text-black font-bold break-words'>Call Us</div>
                    <div className='w-full flex flex-col justify-end items-end space-y-1'>
                    <div className='w-1/2 flex space-x-4 items-center'><span><MapPin className='w-5 h-5'/></span><span className='text-xs font-medium text-black'>Mumbai, Maharashtra. INDIA</span></div>
                    <div className='w-1/2 flex space-x-4 items-center'><span><Phone className='w-5 h-5'/></span><span className='text-xs font-medium text-black'>+(91)87664 93356 </span></div>
                    <div className='w-1/2 flex space-x-4 items-center'><span><MailSearch className='w-5 h-5'/></span><span className='text-xs font-medium text-black'>sales@orangecurrent.com</span></div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default LandingFooter