export const API_URL = process.env.REACT_APP_API_URL_LOAD_FORCASTING_SERVICE +"/api/v1"
  

// export const LEAD_CREATE=API_URL+"/lead/create"
export const LEAD_CREATE=API_URL+"/web/onboarding/npcl/generate-token"

export const NAVIGATE_TO_P2P_PLATFORM=process.env.REACT_APP_API_URL_P2P_WEB_APP+"/auth/onboarding"

// Get Load forcasting  Point

// export const GET_BUCKET_WISE_POINT=API_URL +"/predict"