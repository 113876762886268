import LandinForm from './Component/LandinForm'
import LandingTop from '../Landing/Component/LandingTop'
import React from 'react'
import MobileView from '../Landing/Component/MobileView'
import LandingFooter from '../Landing/Component/LandingFooter'

const LandingMain = () => {
  return (
    <div className='w-full bg-[#EFF8FF]'>

    
    <div className='w-full px-16 h-full '>
        <div className='w-full '>
        <LandingTop/>
        </div>
       <div className='w-full'>
        <LandinForm/>
       </div>
       <div className='w-full'>
        <MobileView/>
       </div>
       <div className='w-full'>
        <LandingFooter/>
       </div>
    </div>
    <div className='w-full text-center text-sm font-medium text-[#9D9D9D]  py-4'>© 2020 Orange Current Technologies Pvt. Ltd. All rights reserved</div>
    </div>
  )
}

export default LandingMain