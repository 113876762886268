import { configureStore } from "@reduxjs/toolkit";
import errorHadlingSlicer from "./Slicer/errorHadlingSlicer";






export const store = configureStore({
    reducer: {
    
        errorBoundry: errorHadlingSlicer,
       
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch