import * as Yup from "yup";
export interface LoginViaConsumerNoProps {
    consumerNo: string ;
 
  }
  export const validationSchemaLoginViaCosumerNo = Yup.object().shape({
    consumerNo: Yup.string()
      .matches(/^[0-9]{10}$/, "Consumer number must be 10 digits")
      .required("Consumer number is required")
      .matches(/^[0-9]+$/, "Consumer number must contain only digits"),

  });
  
  export const LoginViaConsumerNoInitialVal = {
    consumerNo: "",

  };