import { Smartphone } from 'lucide-react';
import React, { InputHTMLAttributes } from 'react';

import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { cn } from 'src/helpers';

interface InputsComp extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    labelClass?: string;
    errorMessage?: string;
    iconName: any,
    regex?: any
    onchange?: (e: any) => void
    value?: string;
    placeholder?: string;
}

const LInputComp: React.FC<InputsComp> = ({ label, placeholder, labelClass, errorMessage, regex, iconName, onchange, value, ...rest }) => {

    // console.log(value)

    return (
        <div className='w-full z-0'>
            <div className='w-full '>
                <div className="w-full relative  shadow-sm">
                    <Label htmlFor={rest.id} className={`text-grayLable text-sm font-bold ml-2 ${labelClass}`}>{label}</Label>
                    <div className="w-full pointer-events-none h-[80%]  absolute flex justify-start items-center px-2">
                        {iconName(cn('text-gray-400 font-bold mt-3'))}
                    </div>
                    <Input
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (regex && regex) {
                                let cleanedValue = e.target.value.replace(/[^0-9]+/g, "");
                                onchange && onchange(cleanedValue)
                            } else {
                                onchange && onchange(e.target.value)
                            }
                        }}
                        placeholder={placeholder}
                        className="block w-full border  border-input border-[#575757] z-0 rounded-md py-1.5 pl-7 pr-20 placeholder:text-gray-600 placeholder:text-xs "
                        {...rest}
                    />
                </div>
                <p className='text-sm text-red-600 ml-2 font-medium'>{errorMessage}</p>
            </div>
        </div>
    );
};

export default LInputComp;
