


// import AchievedJobSummaryMain from '@modules';
import ErrorBoundryMain from './modules/ErrorBoundry/ErrorBoundryMain';
import React from 'react';
import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet } from 'react-router-dom';




function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Outlet />
        <ErrorBoundryMain/>
      </QueryClientProvider>
    </>
  );
}

export default App;
