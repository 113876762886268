import { Outlet } from 'react-router-dom'
import Header from '../Layout/Header'
import React from 'react'

const Applayout = () => {
    return (
        <div className='w-full h-screen bg-[#EFF8FF] '>
            <div className='w-full rounded-none sticky top-0 '>
                {/* <div className='w-full h-10 z-99999'></div> */}
                <Header />
            </div>
            <div className='w-full'>
                <Outlet />
            </div>
        </div>
    )
}

export default Applayout