
import Applayout from '../../src/modules/Layout/Applayout'
import Header from '../../src/modules/Layout/Header'
import LandingMain from '../modules/Landing/LandingMain'
import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'




const AuthRoutes = () => {

    return (
        <Routes >
             <Route element={<Applayout />}>
            <Route path="/*" element={<LandingMain />} />

            <Route index element={<LandingMain />} />
            </Route>
        </Routes>
    )
}

export default AuthRoutes

