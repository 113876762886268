import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import App from '../App';
import AuthRoutes from './AuthRoute';



const { PUBLIC_URL } = process.env;

const Navigations = () => {


   
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes >
                <Route element={<App />}>
                    
                            <Route path="/*" element={<AuthRoutes/>} />
                            <Route path='*' element={<Navigate to="/" />} />
                  
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Navigations;
