import { Button } from 'src/components/ui/button'
import React from 'react'
import SolarHome from "../../../assets/Image/SolarHome.svg"
import { Card } from '../../../components/ui/card'
import Prosumer from "../../../assets/Image/Prosumer.svg"
import Cosumer from "../../../assets/Image/Consumer.svg"
const LandingTop = () => {
  return (
    <div className='w-full'>
      <div className='w-full flex justify-between items-center bg-[#EFF8FF] '>
        <div className='w-full'>
          <h1 className='w-3/4 text-wrap leading-normal text-4xl font-bold text-black '>Make your electricity bills ZERO with rooftop solar</h1>
          <p className='text-[#252525] text-xs font-normal my-2 w-3/5'>Save lakhs of money every year by investing in rooftop solar. Find the right solar panel solution for you using Zircle Solar Marketplace.</p>
          <div className='w-full mt-8'>
            <Button className='text-sm h-8 w-32 text-center rounded-full'>Read More</Button>
          </div>
        </div>
        <div className='w-full h-[300px] flex justify-center items-center'>
          <img src={SolarHome} alt='SolarHome' className='w-3/4' />
        </div>
      </div>
      <div className='w-full flex justify-center items-center' >
        <Card className='w-4/5  flex justify-center items-center h-[120px]'>
          <div className='w-3/4 flex justify-center space-x-4 items-center'>
            <div className='w-16 h-16 flex justify-center items-center rounded-full bg-[#EFF8FF]'><img src={Cosumer} alt='' className='w-10 h-10' /></div>
            <div>
              <h1 className='text-lg font-semibold '>CONSUMER</h1>
              <p className='text-sm font-semibold text-[#9D9D9D] break-words'>Ensure efficient supplier management</p>
            </div>
          </div>
          <div className='w-3/4 flex justify-center space-x-4 items-center'>
            <div className='w-16 h-16 flex justify-center items-center rounded-full bg-[#EFF8FF]'><img src={Prosumer} alt='' className='w-10 h-10' /></div>
            <div>
              <h1 className='text-lg font-semibold '>PROSUMER</h1>
              <p className='text-sm font-semibold text-[#9D9D9D] break-words'>Ensure efficient buyer  management</p>
            </div>
          </div>
        </Card>
      </div>
    </div>
  )
}

export default LandingTop