import React, { ButtonHTMLAttributes } from 'react'
import { Button } from '../../ui/button'
import { Loader2 } from 'lucide-react';
import { id } from 'date-fns/locale';


interface ButtonsProps extends ButtonHTMLAttributes<HTMLButtonElement> {

    btnName?: string
    disabled?: boolean;
    loading?:boolean
id?:string,

}
const Buttons = ({ btnName, disabled, onClick,loading,id }: ButtonsProps) => {
    return (

        <Button onClick={onClick} className='w-full  text-white text-sm font-semibold p-1  rounded-full uppercase px-[20px] py-[10px] cursor-pointer' disabled={disabled || loading} id={id}>{loading?(<> <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Please Wait...</span></>):btnName}</Button>

    )
}

export default Buttons

