import React from 'react'
import GooglePay from "../../../../src/assets/Image/Google_Play.svg"
import Scanner from "../../../../src/assets/Image/Scanner.svg"

const MobileView = () => {
    return (
        <div className='w-full flex justify-between px-20 py-10 '>
            <div className='w-full space-y-3'>
                <h1 className='text-black font-bold text-xl '>Download trading app</h1>
                <h3 className='text-[#9D9D9D] text-sm font-medium'>Download P2P trading app on google play store</h3>
                <div><img src={GooglePay} alt='google-paly' className='w-32 h-10'/></div>
            </div>
           
            <div className='w-full flex justify-end'>
                <div className='space-y-2 flex flex-col justify-center'>
                <h1 className='text-sm font-bold text-black'>Scan here for enquiry</h1>
                <div>
                    <img src={Scanner} alt='Scanner' className='w-32'/>
                </div>
            </div>
            </div>
        </div>
    )
}

export default MobileView