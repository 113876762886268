import React, { useState } from "react";
import LInputComp from "src/components/Common/InputCom/LInputComp";
import { useFormik } from "formik";
import {
  LoginViaConsumerNoInitialVal,
  LoginViaConsumerNoProps,
  validationSchemaLoginViaCosumerNo,
} from "./module";
import { User } from "lucide-react";
import Buttons from "src/components/Common/Buttons/Buttons";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { leadApi } from "src/Services/services";
import {
  updateError,
  updateErrorCode,
  updateSuccessMess,
} from "src/redux/Slicer/errorHadlingSlicer";
import axios, { AxiosError } from "axios";
import { ENUM_STATUS_CODE } from "../../../helpers";
import { NAVIGATE_TO_P2P_PLATFORM } from "src/Services/apis";
const LandinForm = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik<LoginViaConsumerNoProps>({
    initialValues: LoginViaConsumerNoInitialVal,
    onSubmit: async (values, { resetForm, setValues }) => {
      // console.log('Form Values:', values)
      let postData = {
        ...values,
        apiKey:process.env.REACT_APP_API_URL_API_KEY
      };
      henadleSendOtpApi(postData);
      resetForm();
    },
    validationSchema: validationSchemaLoginViaCosumerNo,
  });

  const henadleSendOtpApi = async (postData: any) => {
    setLoading(true);
    try {
      let apiResp = await leadApi(postData);
      dispatch(updateErrorCode(apiResp.code));
      if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {
        // navigate('login/otp')
        // dispatch(updateLoginData(apiResp?.data))
        dispatch(updateSuccessMess(apiResp.message));
        formik.resetForm();
        formik.setValues(LoginViaConsumerNoInitialVal);
     
        // window.open(`${NAVIGATE_TO_P2P_PLATFORM}?consumerNo=${formik?.values?.consumerNo}`, '_blank', 'noopener,noreferrer');
        // window.open(`${NAVIGATE_TO_P2P_PLATFORM}?token=${apiResp?.data?.token}`, '_blank', 'noopener,noreferrer')
        if(apiResp?.data?.token){
          // window.open(`http://localhost:3000/auth/onboarding?token=${apiResp?.data?.token}`, '_blank', 'noopener,noreferrer')
           window.open(`${NAVIGATE_TO_P2P_PLATFORM}?token=${apiResp?.data?.token}`, '_blank', 'noopener,noreferrer')
        }else return 
        
        
      } else {
        dispatch(updateError(apiResp.message));
        dispatch(updateErrorCode(apiResp.code));
        // toast.error(apiResp.message)
      }
      return apiResp.data;
    } catch (err) {
      let error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        dispatch(updateError(error.response?.data.message));
        dispatch(updateErrorCode(error.response?.data.code));
        // toast.error(error.response?.data.message)
      } else {
        dispatch(updateError(error.message));
        // dispatch(updateErrorCode(error.response?.data.code))
        // toast.error(error.message)
      }
    } finally {
      setLoading(false); // Set loading state to false when request completes (whether success or failure)
    }
  };

  return (
    <div className="w-full  pt-10">
      <div className="w-full bg-gray-100 py-6">
        <h1 className="w-full text-xl font-bold text-center text-gray-700">
          Ready to Revolutionize Your Energy Experience with P2P Trading?
        </h1>
        <h3 className="text-xs w-full text-center font-normal text-gray-700 my-4">
          To get started, check your eligibility and register with us. It's
          quick and easy!
        </h3>

        <div className="w-full flex justify-center items-center ">
          <form onSubmit={formik.handleSubmit}>
            <div className="w-full">
              <LInputComp
                iconName={(className: string) => (
                  <User className="lg:w-4 lg:h-4  w-6 h-6 md:mr-2 -mt-3" />
                )}
                placeholder="Consumer Number"
                id="consumerNo"
                type="text"
                maxLength={10}
                regex={new RegExp("/[^0-9]+/g")}
                errorMessage={
                  formik.touched.consumerNo && formik.errors.consumerNo
                    ? formik.errors.consumerNo
                    : ""
                }
                value={formik.values.consumerNo}
                onchange={(e: any) => formik.setFieldValue("consumerNo", e)}
              />
            </div>
            <div className="w-full my-2">
              <div className="">
                <Buttons
                  btnName="Check Eligibility"
                  data-qa="loginbtnviaConNo"
                  type="submit"
                  // disabled={!formik.isValid || formik.isSubmitting}
                  id="login"
                  loading={loading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LandinForm;
