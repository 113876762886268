import { Badge } from '../../components/ui/badge'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import Electricity from "../../assets/Image/ElectricityLogo.svg"
import { Card } from '../../components/ui/card'
import { cn } from 'src/helpers/index'
const Header = () => {
    const {pathname}=useLocation()
    return (
        <div className='w-full  top-0 left-0 z-50'>
            
        <Card className='w-full flex justify-between px-8 items-center h-20 rounded-none  '>
            <div className='ml-4'>
                <img src={Electricity} alt="electricityApi" className='w-32 h-32'/>
            </div>
            <div className=' flex justify-between items-center space-x-10 text-sm font-medium'>
                <NavLink to={'/'} className={cn('hover:underline underline-offset-2',{"text-green-600":pathname?.includes("/")})}>Home</NavLink>
                <NavLink to={'/buyer'} className={cn('hover:underline underline-offset-2',{"":pathname.includes("/buyer")})}>Buyer</NavLink>
                <NavLink to={'/supplier'} className={cn('hover:underline underline-offset-2',{"":pathname.includes("/supplier")})}>Supplier</NavLink>
                <NavLink to={'/trading'} className={cn('hover:underline underline-offset-2',{"":pathname.includes("/trading")})}>How Trading Works</NavLink>

            </div>
            <div>
                <Badge className='w-32  text-sm rounded-full font-semibold h-8 flex items-center justify-center'>Registration</Badge>
            </div>
        </Card>
        </div>
    )
}

export default Header